<!-- Review Modal -->
<!-- <div class="modal fade post-review-model">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered modal-theme modal-dialog-scrollable modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title mt-2 mb-2 fw-700" id="postReviewToProfileModelTitle">Create a Conversation</h4>
        <button type="button" class="close mt-2 font-32" aria-label="Close" data-dismiss="modal" (click) ="closePopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div *ngIf="this.loader === true" class="loader"></div>
      <div class="modal-body px-0 pt-0">
        <p class="font-12 bg-very-light-warning p-3" >
          <b>Friendly Reminder:</b> CitizeX does not censor content but we expect all users to follow our community expectations of truthfulness and honesty. Beware that everyone who looks at your content will know you made it If what you say
          is not true, you could be liable for any damage you cause.
        </p>
        <form class="col-md-12 row m-0 px-3 my-3 square-form" (ngSubmit)="checkVlidation(categoryForm, searchValueForPost)" #categoryForm="ngForm" novalidate enctype="multipart/form-data">
          <div class="col-auto pl-0 pt-0">
            <img *ngIf="defaultProfile.profileImage == '' || defaultProfile.profileImage == null" src="../../../assets/images/noprofile2.png" class="btn btn-img-circle fix-avtar-3 mx-auto mt-0" alt="Page Image" />
            <img *ngIf="defaultProfile.profileImage != '' && defaultProfile.profileImage != null" [src]="defaultProfile.profileImage" onerror="this.src='../../../assets/images/noprofile2.png';" class="btn btn-img-circle fix-avtar-3 mx-auto mt-0" alt="Page Image" />
          </div>

          <div class="col pl-0">
            <p class="col-12 p-0 bold">
              {{ defaultProfile.profileName }} <i *ngIf="verified" class="fi flaticon-success font-success mx-2"></i> <span class="fw-500">@{{ this.defaultProfile.tagName }}</span>
            </p>
            <div class="ng-autocomplete w-100 ng-autocomplete-font-12 mt-1">
              <ng-autocomplete
                name="search"
                id="search"
                class="w-100"
                placeHolder="@"
                #searchString=""
                [data]="searchSuggetionListForPost"
                [(ngModel)]="searchValueForPost"
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                [minQueryLength]="2"
                (inputChanged)="onChangeSearch($event)"
                (selected)="selectEvent($event)"
                (keydown)="clear($event)"
              >
              </ng-autocomplete>
                <div *ngIf="categoryForm.submitted &&mentioned == false && postOnOwnProfile == false && postOnblockProfile == false" class="invalid-feedback d-block">
                  <span>This CitizeX username does not exists</span>
                </div>
                <div *ngIf="postOnOwnProfile == true" class="invalid-feedback d-block">
                  <span>You can not post on your own profile.</span>
                </div>
                <div *ngIf="postOnblockProfile == true" class="invalid-feedback d-block">
                  <span>This CitizeX username does not exists.</span>
                </div>
              <ng-template #itemTemplate let-item class="dropdown-menu search-dropdown">
                <a [innerHTML]="item"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound class="dropdown-menu search-dropdown">
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
            <div class="col-12 p-0 mt-2 z-index-100">
              <div class="form-group review-area ">

                
                <textarea
                  [(ngModel)]="review.content"
                  name="content"
                  id="content"
                  #reviewContent
                  class="form-control p-0 editor"
                  rows="10"
                  #content="ngModel"
                  (keyup)="checkContent(review.content)"
                  required
                  maxlength="300"
                  (searchTerm)="getSuggestions($event)"
                  (itemSelected)="itemSelected()"
                ></textarea>

                <div *ngIf="reviewImageCollection" [ngClass]="reviewImageCollection.length < 7 ? 'col-md-12 mb-2 post-gallery count-' + reviewImageCollection.length : 'col-md-12 post-gallery mt-2 count-6 plus'">
                  <ng-container *ngFor="let mediaFile of reviewImageCollection; let x = index">
                    <button  *ngIf="x < 6" [class]="'btn btn-img img a' + x" [ngClass]="{ video: mediaFile[1] == 'video' }">
                      <img *ngIf="mediaFile[1] == 'image'" [src]="mediaFile[0]" onerror="this.src='../../../assets/images/noprofile2.png';" alt="Post Image" [class]="'img-fluid i' + x" />
                      <video [poster]="thumbnailData[x]" *ngIf="mediaFile[1] == 'video'" width="100%" height="100%" controls [muted]="'muted'" preload="metadata">
                        <source [src]="mediaFile[0]" [type]="mediaFile[2]" />
                      </video>
                      <button type="button" class="btn view-btn delete view-only" (click)="deleteReviewMedia(x, reviewfileUpload); $event.preventDefault()" data-toggle="modal">
                        <span class="icon-inside"><i class="fi flaticon-delete"></i></span>
                      </button>
                      <div class="view-more delete">
                        <button type="button" class="btn view-btn" (click)="onViewMoreReview(reviewImageCollection, x); $event.preventDefault()" data-toggle="modal">
                          <h2>{{ reviewImageCollection.length - 6 }} More</h2>
                        </button>
                        <button type="button" class="btn view-btn delete" (click)="deleteReviewMedia(x, reviewfileUpload); $event.preventDefault()" data-toggle="modal">
                          <span class="icon-inside"><i class="fi flaticon-delete"></i></span>
                        </button>
                      </div>
                    </button>
                  </ng-container>
                </div>
                <div class="col-12 text-right p-0">
                  <button type="button" class="btn btn-link btn-tbl-link-theme font-14 p-0 icon-inside" (click)="reviewfileUpload.click()"><i class="fi flaticon-attach font-14"></i> Upload Media</button>
                </div>
                <input type="file" accept="image/jpg,image/jpeg,image/png,video/mp4,video/quicktime,video/*" class="custom-file-input d-none" #reviewfileUpload id="reviewfileUpload" (change)="uploadReviewfile($event)" multiple appImageOnly/>
              </div>
              <div *ngIf="imgloading == true" class="col-12 px-0 loader-custom mx-auto">
                <div class="view-profileLoader1"></div>
              </div>
            </div>
            <div *ngIf="mediaTypeInvalid" class="invalid-feedback d-block p-1">
              <span>Invalid media type</span>
            </div>
            <div *ngIf="contentInvalid" class="invalid-feedback d-block p-1">
              <span>The uploaded media is identified as adulthood and racy.</span>
            </div>
            <div *ngIf="categoryForm.submitted && content.invalid && this.reviewContentValidation === false" class="invalid-feedback d-block p-1">
              <span>Review cannot be blank.</span>
            </div>
            <div *ngIf="imageExceed" class="invalid-feedback d-block">
              <span>File size should be less than 25 MB</span>
            </div>
            <div *ngIf="maxFiles" class="invalid-feedback d-block">
              <span>Maximum 7 files can be added</span>
            </div>
            <div *ngIf="textContentValid === true" class="invalid-feedback d-block">
              <span>Text is identified as adulthood and racy.</span>
            </div>

            <div class="col-12 pt-3">
              <div class="row justify-content-end">
                <div class="col-8 text-left p-0 d-none">
                  <button type="button" class="btn btn-link btn-tbl-link-theme font-14 p-0 pt-1 icon-inside" (click)="reviewfileUpload.click()"><i class="fi flaticon-attach font-14"></i> Upload Media</button>
                </div>
                <div class="col-4 p-0 text-right">
                  <button type="submit" [disabled]="disable" class="btn btn-black post">Post</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->
<!----------------------Create-Conversation-Modal------->
<div
  class="modal fade post-review-model"
  id="postReviewModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="postReviewModelTitle"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-lg modal-dialog-custom modal-theme modal-dialog-scrollable conversation-Modal"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4
          class="modal-title mt-2 mb-2 fw-700 font-18"
          id="postReviewToProfileModelTitle"
        >
          Create a Conversation
        </h4>
        <button
          type="button"
          class="close mt-2 font-32"
          aria-label="Close"
          data-dismiss="modal"
          (click)="closePopup(categoryForm)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <!-- <div class="col-12 my-3" *ngIf="user.isPolitician">
          <ul class="nav nav-tabs review-nav">
            <li class="nav-item">
              <button class="nav-link ml-4">
                <img src="../../../../assets/images/icn.svg" class="chat-img">
                <span class="font-14 fw-600 ml-2">Chat</span>
              </button>
            </li>
            <li class="nav-item">
              <button class="nav-link active">
                <img src="../../../../assets/images/Vector.svg" class="Vector-img">
                <span class="font-14 fw-600 ml-2">Pooling</span>
              </button>
            </li>
          </ul>
        </div> -->
        <div class="col-12 px-0">
          <p class="font-12 bg-very-light-warning p-3">
            <b>Friendly Reminder:</b> CitizeX does not censor content but we
            expect all users to follow our community expectations of
            truthfulness and honesty. Beware that everyone who looks at your
            content will know you made it If what you say is not true, you could
            be liable for any damage you cause.
          </p>
        </div>
        <form
          class="px-4 py-3"
          (ngSubmit)="checkVlidation(categoryForm)"
          #categoryForm="ngForm"
          novalidate
          enctype="multipart/form-data"
        >
        <div class="col-12 my-2" *ngIf="user?.isPolitician">
          <div class="form-group mt-0">
            <div class="checkbox">
              <input
                type="checkbox"
                id="remMe"
                tabindex="5"
                [(ngModel)]="review.isConstituentsOnlyReply"
              />
              <label for="remMe" class="font-16">Only allow my constituents to reply</label>
            </div>
          </div>
        </div>
          <div class="col-12">
            <div class="form-group mt-0">
              <label for="subject" class="fw-700 font-18">Subject</label>
              <input
                type="text"
                autocomplete="off"
                [(ngModel)]="review.subject"
                class="form-control conversation-modal-input subject-input px-3"
                id="subjectModel"
                name="subjectModel"
                #subjectModel="ngModel"
              />
              <div
                *ngIf="
                  categoryForm.submitted &&
                  (review.subject == '' ||
                    review.subject == undefined ||
                    review.subject == null)
                "
                class="invalid-feedback d-block"
              >
                <span *ngIf="review.subject == '' || review.subject == null"
                  >Subject cannot be blank.</span
                >
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="description" class="fw-700 font-18"
                >Description</label
              >
              <textarea
                [(ngModel)]="review.content"
                name="content"
                id="content"
                #reviewContent
                class="form-control p-0 editor"
                #content="ngModel"
                (keyup)="checkContent(review.content)"
                required
                maxlength="1000"
                (searchTerm)="getSuggestions($event)"
                (itemSelected)="itemSelected()"
              ></textarea>
              <div
                *ngIf="
                  categoryForm.submitted &&
                  content.invalid &&
                  this.reviewContentValidation === false
                "
                class="invalid-feedback d-block"
              >
                <span *ngIf="this.reviewContentValidation === false"
                  >Review cannot be blank.</span
                >
                <span *ngIf="content.errors?.['maxlength']"
                  >You've reached the maximum limit of 1000 characters.</span
                >
              </div>
              <div
                *ngIf="textContentValid === true"
                class="invalid-feedback d-block"
              >
                <span>Text is identified as adulthood and racy.</span>
              </div>
              <div
                *ngIf="categoryForm.submitted && textContentLength"
                class="invalid-feedback d-block"
              >
                <span>You've reached the maximum limit of 1000 characters.</span>
              </div>
            </div>
          </div>
          <div class="col-12 text-sm-right mt-3 mb-2">
            <button
              type="button"
              class="btn btn-outline-black conversation-modal-btn fw-600 text-uppercase font-18"
              aria-label="Close"
              data-dismiss="modal"
              (click)="closePopup(categoryForm)"
            >
              Cancel
            </button>
            <button
              type="submit"
              [disabled]="disable"
              class="btn btn-black ml-2 conversation-modal-btn fw-600 text-uppercase font-18">
              Post
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Success Modal -->
<div
  class="modal fade"
  id="successModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
>
  <div
    class="modal-dialog modal-dialog-custom modal-dialog-centered modal-sm"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-body">
        <h1 class="text-center font-success">
          <i class="fi flaticon-success font-40"></i>
        </h1>
        <h6 class="text-center bold my-3 theme-color">
          Review successfully posted !
        </h6>
        <!-- <p  [routerLink]="['/profile/view-profile/',profile.profileId]" class="text-center theme-color">Review posted Successfully data-dismiss="modal" !</p> -->
        <div class="col-12 text-center">
          <button
            type="button"
            class="btn btn-outline-black mt-4 mb-2 mx-2 py-1 font-12"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </div>
      <!-- .modal-body -->
    </div>
    <!-- .modal-content -->
  </div>
  <!-- .modal-dialog -->
</div>
<!-- #successModal -->
<!-- Success Modal Ends -->
<!-- Review Modal -->

<div
  class="modal fade"
  id="postAReviewImgModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="postAReviewImgModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg modal-carousel" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" class="fi flaticon-cancel"></span>
        </button>
        <div
          id="carouselPostAReview"
          class="carousel slide"
          data-ride="carousel"
        >
          <ol class="carousel-indicators">
            <li
              *ngFor="let media of viewMoreMedia; let x = index"
              data-target="#carouselPostAReview"
              [attr.data-slide-to]="x"
              [ngClass]="viewMoreMediaId == x ? 'active' : ''"
            ></li>
          </ol>
          <div class="carousel-inner">
            <div
              *ngFor="let media of viewMoreMedia; let x = index"
              class="carousel-item"
              [id]="'carousel-item-' + x"
              [ngClass]="{ active: viewMoreMediaId === x }"
            >
              <img
                [src]="media[0]"
                onerror="this.src='../../../assets/images/noprofile2.png';"
                class="img-fluid"
                alt="..."
              />
              <!-- <video src="Balloon.mp4" autoplay="true" loop="true"></video> -->
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carouselPostAReview"
            role="button"
            data-slide="prev"
          >
            <i class="fi bold flaticon-left-arrow-circle"></i>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselPostAReview"
            role="button"
            data-slide="next"
          >
            <i class="fi bold flaticon-right-arrow-circle"></i>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- /#carouselPostAReview -->
      </div>
      <!-- /.modal-body -->
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-carousel -->
</div>
<!-- /.modal -->

<div
  class="modal fade create-profile-model"
  id="createProfileModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="createProfileModalTitle"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div
    class="modal-dialog modal-dialog-custom modal-theme modal-dialog-scrollable"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="createProfileModalTitle">Create Page</h4>
        <button
          type="button"
          class="close"
          (click)="closeCreatePageModal()"
          aria-label="Close"
          data-dismiss="modal"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0" *ngIf="showCreatePopup">
        <app-create-profile
          [isPopup]="true"
          name="{{ username }}"
        ></app-create-profile>
      </div>
    </div>
  </div>
</div>
<!----------------------Post-Modal----------------->
<div
  class="modal fade post-modal community-reminder-modal"
  id="postModal"
  tabindex="-1"
  role="dialog"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-dialog modal-dialog-centered modal-theme" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Community Reminder</h5>
        <button
          type="button"
          class="close font-32"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="theme-color text-justify">
          Friendly Reminder! Citizex does not censor content but we expect all
          users to follow our community expectations of truthfulness and
          honesty. Beware that everyone who looks at your content will know you
          made it. If what you said is not true, you could be liable for any
          damage you cause.
        </p>
        <div class="col-12 text-center">
          <button
            class="btn btn-black mt-4 mb-2 mx-2 py-2 px-sm-2 px-3 font-14"
          >
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<!----------------------Post-Modal-end---------------->
<div
  class="modal fade community-exception-modal"
  id="postModal2"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-theme modal-dialog-scrollable modal-scroll modal-lg"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">
          Community Expectations
        </h5>
        <button
          type="button"
          class="close font-32"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p class="theme-color text-justify my-2">
          <span class="fw-700">Respectful Conduct:</span> Users are expected to
          engage in civil and respectful discourse. Personal attacks,
          harassment, hate speech, or discriminatory language of any kind are
          not tolerated. Disagreements should be expressed respectfully.
        </p>
        <p class="theme-color text-justify my-2">
          <span class="fw-700">Transparency:</span> Users are required to use
          their real identities. Transparency fosters trust and credibility
          within the community.
        </p>
        <p class="theme-color text-justify my-2">
          <span class="fw-700">Factual and Accurate Information:</span> Users
          are encouraged to provide accurate information and support their
          claims with credible sources whenever possible. While differing
          opinions are allowed, spreading deliberate misinformation or
          falsehoods is discouraged. Users should be aware that they may be held
          liable for damages caused by the dissemination of false information.
        </p>
        <p class="theme-color text-justify my-2">
          <span class="fw-700">Open-Mindedness:</span> Users are encouraged to
          be open-minded and consider different perspectives. Constructive
          debates are encouraged, and users should strive to understand and
          engage with diverse viewpoints.
        </p>
        <p class="theme-color text-justify my-2">
          <span class="fw-700">No Spam or Promotions:</span> Users should
          refrain from spamming the platform with irrelevant content or
          promotional materials. Promotions should be relevant to the political
          discourse and adhere to the platform's guidelines.Respect Privacy:
          Users should respect the privacy of others and refrain from sharing
          personal information without consent. Protecting privacy is essential
          to maintaining a safe online environment.
        </p>
        <p class="theme-color text-justify my-2">
          <span class="fw-700">Respect Privacy:</span> Users should respect the
          privacy of others and refrain from sharing personal information
          without consent. Protecting privacy is essential to maintaining a safe
          online environment.
        </p>
        <p class="theme-color text-justify my-2">
          <span class="fw-700">Reporting and Moderation:</span> Community
          members are encouraged to report any violations of the community
          guidelines. If a user's comments are reported for violating the rules,
          they may be marked as spam by community members. Administrators
          reserve the right to review reported content and may remove comments
          that violate the community guidelines. The moderation process aims to
          maintain a respectful and constructive environment for all users.
        </p>
        <form class="row m-0 square-form needs-validation my-3">
          <div class="col-12 text-center">
            <button
              class="btn btn-outline-black mt-4 mb-2 mx-2 py-2 px-sm-2 px-3 font-14 modal-btn"
              data-dismiss="modal"
              (click)="closeModal()"
            >
              Disagree
            </button>
            <button
              class="btn btn-black mt-4 mb-2 mx-2 py-2 px-sm-2 px-3 font-14 modal-btn"
              (click)="postAReview(categoryForm)"
            >
              Agree
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- have feedback modal  -->
<div class="modal fade havefeedbackmodal" tabindex="-1" role="dialog" id="haveFeedback" data-backdrop="static"
  data-keyboard="false">
  <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title font-20 fw-600">Have Feedback?</h5>
        <button type="button" class="close" aria-label="Close" data-dismiss="modal"
          (click)="resetFormData(feedbackForm)">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <form #feedbackForm="ngForm" (ngSubmit)="sendBugReportOrFeedback(feedbackForm)" novalidate>
          <div class="form-group d-flex aling-items-center radio-btn-group">
            <label class="d-flex align-items-center font-18 fw-600 mb-0">Report a bug
              <input type="radio" name="feedback" value="report a bug" [(ngModel)]="reportBugFeedbackDTO.type"
                class="ml-3" required #type="ngModel" /></label>
            <label class="d-flex align-items-center font-18 fw-600 mb-0">Feedback
              <input type="radio" name="feedback" value="feedback" [(ngModel)]="reportBugFeedbackDTO.type" class="ml-3"
                required #type="ngModel" />
            </label>
          </div>
          <div *ngIf="feedbackForm.submitted && type.invalid" class="text-danger mb-2 text-center">
            Please select a feedback type.
          </div>
          <div class="form-group">
            <label for="description" class="font-16 fw-600">Description</label>
            <textarea id="description" class="form-control" rows="5" [(ngModel)]="reportBugFeedbackDTO.description"
              name="description" required maxlength="1000" #description="ngModel" NoLeadingWhitespace></textarea>
            <div *ngIf="feedbackForm.submitted && description.invalid" class="text-danger">
              <span *ngIf="description.errors?.['required']">Description is required.</span>
              <span *ngIf="description.errors?.['maxlength']">You've reached the maximum limit of 1000
                characters.</span>
            </div>
          </div>
          <div class="d-flex justify-content-end mr-4">
            <button type="button" class="btn btn-outline-black mt-4 pt-2 mb-3 mx-2 font-16 fw-600" data-dismiss="modal"
              (click)="resetFormData(feedbackForm)">
              Cancel
            </button>
            <button type="submit" class="btn btn-black mt-4 pt-2 mb-3 mx-2 font-16 fw-600">
              Report
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!------------have feedback modal----------->
 <!------------------------Chat-Conversion-Modal----------------------->
 <div
  class="modal fade post-review-model"
  id="ChatConversionModel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="postReviewModelTitle"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
>
  <div
    class="modal-dialog modal-dialog-centered modal-lg modal-dialog-custom modal-theme modal-dialog-scrollable conversation-Modal"
    role="document"
  >
    <div class="modal-content">
      <div class="modal-header">
        <h4
          class="modal-title mt-2 mb-2 fw-700 font-18"
          id="postReviewToProfileModelTitle"
        >
          Create a Conversation
        </h4>
        <button
          type="button"
          class="close mt-2 font-32"
          aria-label="Close"
          data-dismiss="modal"
          (click)="closePopup(categoryForm)"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-0 chat-conversion-model">
        <!-- <div class="col-12 my-3">
          <ul class="nav nav-tabs review-nav">
            <li class="nav-item">
              <button class="nav-link ml-4">
                <img src="../../../../assets/images/icn.svg" class="chat-img">
                <span class="font-14 fw-600 ml-2">Chat</span>
              </button>
            </li>
            <li class="nav-item">
              <button class="nav-link active">
                <img src="../../../../assets/images/Vector.svg" class="Vector-img">
                <span class="font-14 fw-600 ml-2">Pooling</span>
              </button>
            </li>
          </ul>
        </div> -->
        <div class="col-12 px-0">
          <p class="font-12 bg-very-light-warning p-3">
            <b>Friendly Reminder:</b> CitizeX does not censor content but we
            expect all users to follow our community expectations of
            truthfulness and honesty. Beware that everyone who looks at your
            content will know you made it If what you say is not true, you could
            be liable for any damage you cause.
          </p>
        </div>
        <form
          class="px-4 py-3"
          (ngSubmit)="checkVlidation(categoryForm)"
          #categoryForm="ngForm"
          novalidate
          enctype="multipart/form-data"
        >
        <div class="col-12 my-2">
          <div class="form-group mt-0">
            <div class="checkbox">
              <input
                type="checkbox"
                id="remMe"
                tabindex="5"
              />
              <label for="remMe" class="font-16 fw-700">Only allow my constituents to reply</label>
            </div>
          </div>
        </div>
          <div class="col-12">
            <div class="form-group mt-0">
              <label for="subject" class="fw-700 font-18">Subject</label>
              <input
                type="text"
                autocomplete="off"
                [(ngModel)]="review.subject"
                class="form-control conversation-modal-input subject-input px-3"
                id="subjectModel"
                name="subjectModel"
                #subjectModel="ngModel"
              />
              <div
                *ngIf="
                  categoryForm.submitted &&
                  (review.subject == '' ||
                    review.subject == undefined ||
                    review.subject == null)
                "
                class="invalid-feedback d-block"
              >
                <span *ngIf="review.subject == '' || review.subject == null"
                  >Subject cannot be blank.</span
                >
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="description" class="fw-700 font-18"
                >Description</label
              >
              <!-- <textarea class="form-control conversation-modal-input px-3 py-2" [(ngModel)]="review.content" id="description" name="description"  rows="5"  #description="ngModel"  (keyup)="checkContent(review.content)"  (searchTerm)="getSuggestions($event)" (itemSelected)="itemSelected()"required maxlength="300" ></textarea> -->
              <textarea
                [(ngModel)]="review.content"
                name="content"
                id="content"
                #reviewContent
                class="form-control p-0 editor"
                #content="ngModel"
                (keyup)="checkContent(review.content)"
                required
                maxlength="1000"
                (searchTerm)="getSuggestions($event)"
                (itemSelected)="itemSelected()"
              ></textarea>
              <div
                *ngIf="
                  categoryForm.submitted &&
                  content.invalid &&
                  this.reviewContentValidation === false
                "
                class="invalid-feedback d-block"
              >
                <span *ngIf="this.reviewContentValidation === false"
                  >Review cannot be blank.</span
                >
                <span *ngIf="content.errors?.['maxlength']"
                  >You've reached the maximum limit of 1000 characters.</span
                >
              </div>
              <div
                *ngIf="textContentValid === true"
                class="invalid-feedback d-block"
              >
                <span>Text is identified as adulthood and racy.</span>
              </div>
              <div
                *ngIf="categoryForm.submitted && textContentLength"
                class="invalid-feedback d-block"
              >
                <span>You've reached the maximum limit of 1000 characters.</span>
              </div>
            </div>
          </div>
          <div class="col-12 text-sm-right mt-3 mb-2">
            <button
              type="button"
              class="btn btn-outline-black conversation-modal-btn fw-600 text-uppercase font-18"
              aria-label="Close"
              data-dismiss="modal"
              (click)="closePopup(categoryForm)"
            >
              Cancel
            </button>
            <button
              type="submit"
              [disabled]="disable"
              class="btn btn-black ml-2 conversation-modal-btn fw-600 text-uppercase font-18"
            >
              Post
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>