// user class used as Data Transfer object for transferring userdata
export class User {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string | null;
  userName: string;
  password: string;
  socialSecurityNumber: string;
  activationCode: string;
  passwordconf: string;
  signupStep: number;
  firstLogIn: boolean;
  verified: boolean;
  active: boolean;
  otp: string;
  phoneNumber: string;
  veriffFlag: boolean;
  textVerified: boolean;
  veriffSessionId: string;
  level: string;
  defaultProfileId: number;
  createdDate: string;
  verbShowingDate: string;
  verbeFrequency: number;
  showCongratsScreen: string;
  isPosted: boolean;
  reviewProfileId: number;
  isTourDone: boolean;
  isPolitician: boolean;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  veriffStatus: string;

  constructor() {
    this.userId = 0;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.dateOfBirth = '';
    this.userName = '';
    this.password = '';
    this.socialSecurityNumber = '';
    this.activationCode = '';
    this.passwordconf = '';
    this.signupStep = 0;
    this.firstLogIn = false;
    this.verified = false;
    this.active = false;
    this.otp = '';
    this.phoneNumber = '';
    this.veriffFlag = false;
    this.textVerified = false;
    this.veriffSessionId = '';
    this.level = '';
    this.defaultProfileId = 0;
    this.createdDate = '';
    this.verbShowingDate = '';
    this.verbeFrequency = 0;
    this.showCongratsScreen = '';
    this.isPosted = false;
    this.reviewProfileId = 0;
    this.isTourDone = false;
    this.isPolitician = false;
    this.address = '';
    this.city = '';
    this.state = '';
    this.country = '';
    this.veriffStatus = '';
  }
}
