// Used as Data Transfer object for representing the review done on the profile.
export class Review {
  id: number;
  content: string;
  media: string[];
  profileId: number;
  byUserId: number;
  byProfileId: number;
  isReview: boolean;
  reviewId: number | null;
  byProfileName: string;
  byProfileImage: string;
  onProfileTagName: string;
  daysAgo: string;
  responseCount: number;
  createdBy: number;
  byProfileTagName: string;
  level: string;
  createdDate: string;
  responseId?: number;
  mentionProfileIds: string;
  priority: number;
  finalPriority: number;
  responseLevel: number;
  response: Review | null;
  insertResponse: boolean;
  subject: string;
  isConstituentsOnlyReply: boolean;
  city: string;
  state: string;
  country: string;

  constructor() {
    this.id = 0;
    this.content = '';
    this.media = [];
    this.profileId = 0;
    this.byUserId = 0;
    this.byProfileId = 0;
    this.isReview = false;
    this.reviewId = null;
    // this.byUserName = "";
    this.byProfileName = '';
    this.byProfileImage = '';
    this.onProfileTagName = '';
    this.daysAgo = '';
    this.responseCount = 0;
    this.createdBy = 0;
    this.byProfileTagName = '';
    this.level = '';
    this.createdDate = '';
    this.responseId = 0;
    this.priority = 0;
    this.mentionProfileIds = '';
    this.finalPriority = 0;
    this.responseLevel = 0;
    this.insertResponse = false;
    this.response = null;
    this.subject = '';
    this.isConstituentsOnlyReply = false;
    this.city = '';
    this.state = '';
    this.country = '';
  }
}
