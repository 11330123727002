import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { NgForm } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Subscription } from 'rxjs';
import { Profile } from '../../../core/models/profile.model';
import { User } from '../../../core/models/user.model';
import { Review } from '../../../core/models/review.model';
import { AuthService } from '../../../core/services/auth.service';
import { ProfileService } from '../../../core/services/profile.service';
import { ApiService } from '../../../core/services/api.service';
import { DesignService } from '../../../core/services/design.service';
import { LoaderService } from '../../../core/loader.service';
import { UserService } from '../../../core/services/user.service';
import { Router } from '@angular/router';
import { ReportBugFeedbackDTO } from '../../../core/models/reportBugFeedback.modal';

declare var $: any;
declare var CKSource: any;

@Component({
  selector: 'app-post-review-model',
  templateUrl: './post-review-model.component.html',
  styleUrls: ['./post-review-model.component.scss'],
})
export class PostReviewModelComponent implements OnInit {
  @Output() reviewCreated = new EventEmitter<{ ProfileID: number }>();

  user: User = new User();
  profile: Profile = new Profile();
  review = new Review();
  errMsg: string = '';
  mentioned: boolean = false;
  public reviewImageCollection: any[] = [];
  public reviewImageCollectionUpload: File[] = [];
  defaultProfile = new Profile();

  searchValueForPost = '';
  searchSuggetionListForPost: string[] = [];
  totalMediaSize: number = 0;
  // mediaOverflow: boolean;
  mediaTypeInvalid: boolean = false;
  imageExceed: boolean = false; // TO show error when image size exceeded than limit.
  viewMoreMedia = [];
  viewMoreMediaId = null;
  maxFiles: boolean = false;
  reviewContentValidation: boolean = false;
  profileTagValidation: boolean = false;
  profileList = new Array<Profile>();
  itemSelectedFleg: boolean = false; // If angular mention is clicked or not
  // Angular Mentions
  // initialized mentionConfig object used for configuration of @ngular mentions dropdown.
  items: any[] = [];
  verified: boolean = false;
  username: string = '';
  loader: boolean = false;
  contentInvalid: boolean = false;
  thumbnailData: string[] = [];
  clickEventsubscription: Subscription;
  verbShowing: any;
  headerStyle: string;
  headerStyleSubscription: any;
  imgloading: boolean = false;
  allMensionList: any[] = [];
  postOnOwnProfile: boolean = false;
  postOnblockProfile: boolean = false;
  textContentValid = false;
  disable = false;
  blockAndBlockedByProfileList: string[] = [];
  textContentLength = false;
  reportBugFeedbackDTO: ReportBugFeedbackDTO = new ReportBugFeedbackDTO();
  constructor(
    private authService: AuthService,
    private apiService: ApiService,
    private profileService: ProfileService,
    private designService: DesignService,
    private loaderService: LoaderService,
    private userService: UserService,
    private router: Router
  ) {
    this.clickEventsubscription = this.designService
      .getClickEvent()
      .subscribe(() => {
        this.user = this.authService.getUser();

        this.verbShowing = this.calculateVerbDate(this.user.verbShowingDate); // use for show msg periodically
      });

    // veriffpage
    this.headerStyle = designService.headerStyle;
    this.headerStyleSubscription =
      this.designService.headerStyleChange.subscribe((value) => {
        this.headerStyle = value;
        if (this.headerStyle == 'profile' && this.user !== null) {
          this.verbShowing = this.calculateVerbDate(this.user.verbShowingDate); // use for show msg periodically
        }
      });
  }

  ngOnInit() {
    this.user = this.authService.getUser();
    var user = this.authService.getUser();
    if (user != null || this.authService.getLogflag()) {
      this.defaultProfile.profileName = user.defaultProfileName;
      this.defaultProfile.profileImage = user.defaultProfileImage;
      this.defaultProfile.tagName = user.defaultProfileTagName;
      this.defaultProfile.id = user.defaultProfileId;
      this.verified = user.verified;
      if (this.user.verbShowingDate == null) {
        this.verbShowing = true;
        console.log('verb showing if', this.verbShowing);
      } else {
        console.log('verb showing else', this.verbShowing);
        this.verbShowing = this.calculateVerbDate(this.user.verbShowingDate); // use for show msg periodically
      }
    }
    this.ckeditor(document.querySelector('#content'));
    if (this.user != null && this.user.defaultProfileId != null) {
      this.getBlockProfileDetails();
    }
  }

  calculateVerbDate(dateSent: any) {
    dateSent = new Date(dateSent);
    let result = false;
    if (!!dateSent) {
      var date1 = formatDate(new Date(), 'yyyy-MM-dd', 'en_US'); // current date
      var date2 = formatDate(dateSent, 'yyyy-MM-dd', 'en_US'); // verbShowing date
      result = date1 >= date2 ? true : false;
    } else {
      result = true;
    }
    console.log('################################post review', result);
    return result;
  }

  testTagName(searchValueForPost: string) {
    this.loaderService.disable = true;
    let tagName = '';
    if (searchValueForPost.indexOf('@') === 0) {
      tagName = searchValueForPost.slice(1);
    } else {
      tagName = searchValueForPost;
    }
    console.log('tagname:  ' + tagName);
    const params = new HttpParams().set('tagName', tagName); // getting profile Id from route.

    // APi call
    this.profileService.getProfileByTagName(params).subscribe((res) => {
      console.log(res);
      this.postOnOwnProfile = false;
      if (res.success === 0) {
        console.log('Here 1 : ' + res.success);
        this.profile = res.data; // All the responses retrieved.
        this.mentioned = true;
        if (this.user.defaultProfileId !== res.data.profileId) {
          console.log('Here 1 : ' + res.success);
          this.profile = res.data; // All the responses retrieved.
          this.mentioned = true;
        } else {
          //this.mentioned = false;
          this.postOnOwnProfile = true;
        }
      } else {
        console.log('Here 2 : ' + res.success);
        this.errMsg = 'Profile Not found.';
        this.mentioned = false;
        console.log(this.errMsg);
      }
    });
  }

  onChangeSearch(event: any) {
    this.loaderService.disable = true;
    //this.mentioned = false;
    if (event !== undefined && event !== null && event !== '') {
      if (event.indexOf('@') === 0) {
        event = event.slice(1);
      }
      const params = new HttpParams()
        .set('tagNameSubString', event)
        .set('limit', '5')
        .set('profileId', this.user.defaultProfileId.toString()); // getting profile Id from route.

      // API Call
      this.profileService.getSuggestions(params).subscribe((res) => {
        if (res.success === 0) {
          res.data.forEach((element: any) => {
            //this.mentioned = true;
            const tag = '@' + element.tagName;
            this.searchSuggetionListForPost.indexOf(tag) === -1
              ? this.searchSuggetionListForPost.push(tag)
              : '';
          });
        } else {
          //this.mentioned = false;
        }
      });
    }
    this.postOnOwnProfile = false;
    this.postOnblockProfile = false;
  }

  // Uploading images in the reviews.
  uploadReviewfile(event: any) {
    this.imageExceed = false;
    for (let val = 0; val < event.target.files.length; val++) {
      let file = event.target.files[val];
      let fullType = file.type;

      if (
        fullType == 'video/avi' &&
        fullType == 'image/jpeg' &&
        fullType == 'image/png'
      ) {
        this.mediaTypeInvalid = false;
      }

      let fileSize: number = file.size;
      if (fileSize > 26214400) {
        this.imageExceed = true;
        return;
      } else {
        this.imageExceed = false;
      }
      const extention = file.name.split('.').pop();
      let type = fullType.split('/')[0];
      if (fullType === 'audio/ogg') {
        type = 'video';
      }

      if (extention === 'mkv') {
        fullType = 'video/mp4';
      }

      var reader = new FileReader();
      reader.onload = (event: any) => {
        let contentHeader;

        if (file.type === 'image/jpeg') {
          contentHeader = 'image/jpeg';
        } else if (file.type === ' image/png') {
          contentHeader = 'image/png';
        } else {
          contentHeader = null;
        }

        if (contentHeader == null) {
          if (this.reviewImageCollectionUpload.length >= 7) {
            this.maxFiles = true;
            return;
          } else {
            this.reviewImageCollection.push([
              event.target.result,
              type,
              fullType,
            ]);
            this.reviewImageCollectionUpload.push(file);
          }
        } else {
          this.imgloading = true;
          this.loaderService.disable = true;
          this.apiService.checkContent(file, contentHeader).subscribe((res) => {
            this.imgloading = false;
            console.log(res.IsImageAdultClassified);
            let isImageAdultClassified: boolean = res.IsImageAdultClassified;
            if (isImageAdultClassified == true) {
              this.contentInvalid = true;
            } else {
              this.contentInvalid = false;
              if (this.reviewImageCollectionUpload.length >= 7) {
                this.maxFiles = true;
                return;
              } else {
                this.reviewImageCollection.push([
                  event.target.result,
                  type,
                  fullType,
                ]);
                this.reviewImageCollectionUpload.push(file);
              }
            }
          });
        }
      };
      reader.readAsDataURL(file);
    }
    event.target.value = '';
  }

  checkContent(content: string) {
    console.log(content.length);
    if (content.length > 1000) {
      this.textContentLength = true; // Or set another flag for maxlength error
    } else {
      this.textContentLength = false;
    }
    if (content.trim().toString().length <= 0) {
      this.reviewContentValidation = false;
      return;
    } else {
      this.reviewContentValidation = true;
      this.checkTextContent(content);
    }
  }

  checkVlidation(categoryForm: NgForm) {
    this.user = this.authService.getUser();

    if (this.review.content != null) {
      if (this.review.content.trim().toString().length == 0) {
        this.reviewContentValidation = false;
        return;
      }
    }

    if (this.review.subject == '' || this.review.subject == null) {
      return;
    }

    // if (this.mentioned == false) {
    //   console.log('Incorrect profile mentioned');
    //   return;
    // }
    if (categoryForm.invalid) {
      if (this.review.content == '') {
        this.reviewContentValidation = false;
      }
      return;
    }
    if (this.review.content.trim().toString().length == 0) {
      this.reviewContentValidation = false;
      return;
    }
    if (this.user.isPosted == false) {
      $('#postModal2').modal('show');
    } else {
      //this.postReview(categoryForm);
      this.postAReview(categoryForm);
    }
  }

  postReview(categoryForm: any) {
    // if (searchValueForPost != '' || searchValueForPost != null) {
    //   this.loaderService.disable = true;
    //   let tagName = '';
    //   if (searchValueForPost.indexOf('@') === 0) {
    //     tagName = searchValueForPost.slice(1);
    //   }
    //   if (this.defaultProfile.tagName === tagName) {
    //     this.postOnOwnProfile = true;
    //     return;
    //   }
    //   if (this.blockAndBlockedByProfileList.includes(tagName)) {
    //     this.postOnblockProfile = true;
    //     return;
    //   }
    //   const params = new HttpParams().set('tagName', tagName); // getting profile Id from route.
    //   this.disable = true;
    //   // APi call
    //   this.profileService.getProfileByTagName(params).subscribe((res) => {
    //     this.mentioned = false;
    //     console.log(res);
    //     if (res.success === 0 && this.postOnOwnProfile === false) {
    //       console.log('Here 1 : ' + res.success);
    //       this.profile = res.data; // All the responses retrieved.
    //       this.mentioned = true;
    //       this.postAReview(categoryForm);
    //     } else {
    //       console.log('Here 2 : ' + res.success);
    //       this.errMsg = 'Profile Not found.';
    //       this.mentioned = false;
    //       this.disable = false;
    //       return;
    //     }
    //   });
    //  }
  }

  // for posting a review, will call the post a review API.
  postAReview(categoryForm: any) {
    this.loaderService.disable = true;
    if (categoryForm.invalid) {
      console.log(
        'Form is invalid. Please correct the errors before submitting.'
      );
      return;
    }

    var user = this.authService.getUser();
    this.review.byUserId = user['userId'];
    this.review.profileId = 0;
    this.review.byProfileId = user['defaultProfileId'];
    this.review.isReview = true;
    this.review.reviewId = null;
    if (this.user.city != null) {
    this.review.city = this.user.city;
    }
    if (this.user.state != null) {
    this.review.state = this.user.state;
    }
    if (this.user.country) {
    this.review.country = this.user.country;
    }
    // this.review.mentionProfileIds = this.profile.profileId.toString();
    let content = this.review.content;

    if (content.includes('@')) {
      let mensionIds =
        this.review.mentionProfileIds + ',' + this.getAllMension(content);
      //this.review.mentionProfileIds = mensionIds;
      /*to make mentionProfileIds unique*/
      let mentionProfileIdList = mensionIds
        .split(',')
        .filter((item, i, ar) => ar.indexOf(item) === i);
      console.log('mentionProfileIdList=', mentionProfileIdList.toString());
      this.review.mentionProfileIds = mentionProfileIdList.toString();
    }

    const { insertResponse, ...result } = this.review;
    const formData: FormData = new FormData();
    formData.append('reviewDTO', JSON.stringify(result));
    var i;
    var mediaSize = 0;
    if (this.reviewImageCollectionUpload.length != 0) {
      for (i = 0; i < this.reviewImageCollectionUpload.length; i++) {
        formData.append('mediaFile', this.reviewImageCollectionUpload[i]);
        mediaSize = mediaSize + this.reviewImageCollectionUpload[i].size;
      }
    }
    if (mediaSize > 300000) {
      // 300kb
      this.loader = true;
    }

    if (this.textContentValid) {
      return;
    }

    // Api call
    this.profileService.postAReview(formData).subscribe((res) => {
      if (res.success == 0) {
        //this.reviewCreated.emit({ ProfileID:  this.review.byProfileId });
        $('#postModal2').modal('hide');
        $('#postReviewModel').modal('hide');
        $('#successModal').modal('show');
        if (this.user.isPosted == false) {
          setTimeout(() => {
            $('#haveFeedback').modal('show'); // Call the tour from the service
          }, 500);  
        }
        
        this.postOnOwnProfile = false;
        this.postOnblockProfile = false;
        this.reviewContentValidation = false;
        this.imageExceed = false;
        this.mediaTypeInvalid = false;
        this.maxFiles = false;
        this.mentioned = true;
        categoryForm.resetForm();
        this.review.content = '';
        this.review.mentionProfileIds = '';
        document
          .getElementsByClassName(
            'ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred'
          )[0]
          .remove();
        this.ckeditor(document.querySelector('#content'));
        this.reviewImageCollection = [];
        this.reviewImageCollectionUpload = [];
        this.verbShowing = this.calculateVerbDate(this.user.verbShowingDate);
        this.disable = false;
        this.review.subject = '';
        if (this.user.isPosted == false) {
          this.user.isPosted = true;
          this.authService.setSessionData(this.user);
          this.updateUser();
        }
        const currentUrl = this.router.url;
        const targetUrl = '/homepage'; // Replace with the URL you want to match

        if (currentUrl === targetUrl) {
          window.location.reload();
        }
      } else {
        alert('failed to post a review.');
        this.disable = false;
      }
    });

    var date1 = formatDate(new Date(), 'yyyy-MM-dd', 'en_US'); // current date
    var date2 = formatDate(this.user.verbShowingDate, 'yyyy-MM-dd', 'en_US'); // verbShowing date
    if (date1 >= date2) {
      this.setUserVerbDetail();
    }
  }

  setUserVerbDetail() {
    var user = this.authService.getUser();
    var verbeFrequency = user['verbeFrequency'];
    if (verbeFrequency == null) {
      verbeFrequency = 7;
    } else if (verbeFrequency == '7') {
      verbeFrequency = 15;
    } else if (verbeFrequency == '15') {
      verbeFrequency = 30;
    } else if (verbeFrequency == '30') {
      verbeFrequency = 30;
    }

    var someDate = new Date();
    someDate.setDate(someDate.getDate() + verbeFrequency); //number  of days to add, e.x. 15 days
    let nextdate = JSON.stringify(someDate);
    nextdate = nextdate.slice(1, 11);
    console.log('date:' + nextdate);
    this.review.byUserId = user['userId'];

    const params = new HttpParams()
      .set('userId', user['userId'])
      .set('verbShowingDate', nextdate)
      .set('verbFrequency', verbeFrequency);

    this.profileService.setVerbDetail(params).subscribe((res) => {
      var user = res.data;
      var sessionUser = this.authService.getUser();
      sessionUser.verbShowingDate = user.verbShowingDate;
      sessionUser.verbeFrequency = user.verbeFrequency;
      this.authService.setSessionData(sessionUser);
      this.designService.sendClickEvent();
    });
  }

  // get profile name suggestions when user starts typing.
  getSuggestions(event: any) {
    this.loaderService.disable = true;
    if (event !== undefined && event !== null && event !== '') {
      let promise: Promise<any>;
      promise = promise = new Promise((resolve, reject) => {
        const params = new HttpParams()
          .set('tagNameSubString', event)
          .set('limit', '5'); // getting profile Id from route.

        // API Call
        this.profileService.getSuggestions(params).subscribe((res) => {
          const respData = res;
          if (respData.success == 0) {
            this.items = [];
            this.profileList = respData.data;
            for (let profile of this.profileList) {
              const temp: string = profile.profileImage;
              if (temp != null) {
                if (
                  temp.substring(temp.lastIndexOf('.') + 1, temp.length) ===
                    'jpeg' ||
                  temp.substring(temp.lastIndexOf('.') + 1, temp.length) ===
                    'png' ||
                  temp.substring(temp.lastIndexOf('.') + 1, temp.length) ===
                    'jpg'
                ) {
                } else {
                  profile.profileImage = '';
                }
              }
              this.items.push({
                id: `@${profile.tagName}`,
                text: `@${profile.tagName}`,
                name: profile.tagName,
                img: profile.profileImage,
              });

              this.allMensionList.push({
                id: profile.id,
                name: profile.tagName,
              });
            }
            resolve('');
          } else {
            reject();
          }
        });
      });
      return promise;
    } else {
      // Return null or empty promise when the event is invalid
      return Promise.resolve(null);
    }
  }

  //to-do on delete review media
  deleteReviewMedia(index: any, element: any) {
    element.value = '';
    this.reviewImageCollectionUpload.splice(index, 1);
    this.thumbnailData.splice(index, 1);
    this.reviewImageCollection.splice(index, 1);
    if (this.reviewImageCollectionUpload.length <= 7) {
      this.maxFiles = false;
      return;
    }
  }

  onViewMoreReview(media: any, id: any) {
    this.viewMoreMedia = media;
    this.viewMoreMediaId = id;
    $('#postAReviewImgModal').modal('show');
  }

  // If mention selected then donot submit form
  itemSelected() {
    this.itemSelectedFleg = true;
  }

  ckeditor(element: any) {
    const watchdog = new CKSource.Watchdog();
    const CKConfig = {
      toolbar: {
        items: ['bold', 'italic', 'underline'],
      },
      language: 'en',
      mention: {
        feeds: [
          {
            marker: '@',
            feed: (searchString: any) => {
              console.log(
                'PostReviewModelComponent -> ngOnInit -> searchString',
                searchString
              );
              this.username = searchString;
              return this.getSuggestions(searchString).then(() => {
                if (this.items.length !== 0) {
                  return this.items;
                } else {
                  return [{ name: searchString, notFound: true }];
                }
              });
            },
            minimumCharacters: 2,
            itemRenderer: customItemRenderer,
          },
        ],
      },
    };

    (window as any).watchdog = watchdog;

    watchdog.setCreator((element: any, config: any) => {
      return CKSource.Editor.create(element, config).then((editor: any) => {
        editor.model.document.on('change:data', () => {
          this.review.content = editor.getData();
          this.checkContent(this.review.content);
          $(`#${editor.sourceElement.id}`).trigger('change');
        });
        return editor;
      });
    });

    watchdog.setDestructor((editor: any) => {
      return editor.destroy();
    });

    watchdog.on('error', handleError);

    watchdog.create(element, CKConfig).catch(handleError);

    watchdog.on('restart', () => {
      watchdog.destroy();
      watchdog.create(element, CKConfig).catch(handleError);
    });

    function handleError(error: any) {
      console.error('Oops, something went wrong!');
      console.error(error);
    }

    function customItemRenderer(item: any) {
      console.log(
        'PostReviewModelComponent -> customItemRenderer -> item',
        item
      );
      const itemElement = document.createElement('button');
      if (item.notFound) {
        itemElement.className = 'row m-0 NotFound';
        itemElement.innerHTML = `
        <div class="col ck-mentions-name">${item.name}</div>
        <div class="col-auto"><button type="button" class="btn-NotFound" data-mention="${item.name}" >Create Page</button></div>`;
      } else {
        itemElement.className = 'row m-0';
        const imgSrc = item.img ? item.img : '/assets/images/noprofile2.png';
        itemElement.innerHTML = `
            <div class="col-auto">
              <img src="${imgSrc}" class="ck-mentions-img">
            </div>
            <div class="col ck-mentions-name">${item.name}</div>`;
      }
      return itemElement;
    }

    $(document).on('click', '.btn-NotFound', (event: any) => {
      console.log(
        'PostReviewModelComponent -> notFoundCreatePage -> event',
        event
      );
      const element = event.target;
      const val = element.getAttribute('data-mention');
      console.log('PostReviewModelComponent -> ngOnInit -> val', val);
      this.username = val;
      $('#postReviewModel').addClass('slide-right');
      $('#postReviewToProfileModel').addClass('slide-right');
      $('#createProfileModal').modal('show');

      this.designService.showCreatePopup = true;

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    });
  }

  closeCreatePageModal() {
    $('#postReviewModel').removeClass('slide-right');
    $('#postReviewToProfileModel').removeClass('slide-right');
    this.designService.showCreatePopup = false;
  }

  clear(event: any) {
    if (event.keyCode == 13) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  public get showCreatePopup(): boolean {
    return this.designService.showCreatePopup;
  }

  getAllMension(content: string) {
    var userTagsList: any[] = [];
    let mensionsList: string = ''; // all tags use in post content
    let tmp = content.replace(/[:<>=";/]/g, '');

    function extract([beg, end]: any) {
      const matcher = new RegExp(`${beg}(.*?)${end}`, 'gm');
      const normalise = (str: any) => str.slice(beg.length, end.length * -1);
      return function (str: any) {
        return str.match(matcher).map(normalise);
      };
    }
    const stringExtractor = extract(['@', '@']);
    userTagsList = stringExtractor(tmp);

    const result = Array.from(new Set(this.allMensionList.map((s) => s.id))) // distinct array
      .map((id) => {
        return {
          id: id,
          name: this.allMensionList.find((s) => s.id == id).name,
        };
      });
    console.log(result);
    result.forEach((element) => {
      // set user tags profile id in mensionsList
      if (userTagsList.includes(element.name)) {
        console.log(element.name);
        mensionsList += element.id + ',';
      }
    });

    if (mensionsList.substr(mensionsList.length - 1) === ',') {
      mensionsList = mensionsList.slice(0, -1);
    }
    return mensionsList;
  }

  checkTextContent(content: any) {
    this.textContentValid = false;
    let removeTags = content.replace(/<[^>]*>/g, '').replace('&nbsp;', '');
    console.log('content :', removeTags);
    this.loaderService.disable = true;
    this.apiService.checkTextContent(removeTags).subscribe((res) => {
      console.log('resy: ', res);
      if (res.Classification.ReviewRecommended) {
        this.textContentValid = true;
      }
    });
  }

  closePopup(form?: NgForm) {
    if (form) {
      form.resetForm();
    }
    $('#postReviewModel').on('hidden.bs.modal', () => {
      $(this).find('input,textarea,select,form').trigger('reset');
    });
    this.mentioned = false;
    this.postOnblockProfile = false;
    this.postOnOwnProfile = false;
    this.review.content = '';
    this.review.subject = '';
    setTimeout(() => {
      document
        .getElementsByClassName(
          'ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred'
        )[0]
        .remove();
      this.ckeditor(document.querySelector('#content'));
    }, 300);
  }
  selectEvent(value: any) {
    this.searchValueForPost = value;
    this.testTagName(value);
    this.onChangeSearch(value);
  }
  getBlockProfileDetails() {
    const params = new HttpParams().set(
      'profileId',
      this.user.defaultProfileId.toString()
    );
    this.profileService.getBlockProfiles(params).subscribe((res) => {
      console.log('response for the blocked profiles', res);
      const blockProfileList = res.data.blockedProfileList;
      const blockedByProfileList = res.data.blockedByProfileList;
      for (const profile of blockedByProfileList) {
        this.blockAndBlockedByProfileList.push(profile.tagName);
      }
      for (const profile of blockProfileList) {
        this.blockAndBlockedByProfileList.push(profile.tagName);
      }
    });
  }

  // Purpose : For updating user
  updateUser() {
    this.userService.editUser(this.user).subscribe((res) => {
      this.authService.setSessionData(this.user);
      if (res.success == 0) {
        console.log('User updated successfully:' + JSON.stringify(this.user));
        console.log(
          'user object check loginbefore:' +
            JSON.stringify(this.authService.getUser())
        );
      } else {
        console.log('Error while updating user');
      }
    });
  }

  closeModal() {
    $('#postReviewModel').modal('hide');
    this.closePopup();
  }
  sendBugReportOrFeedback(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.reportBugFeedbackDTO.userId = this.user.userId;

    this.userService
      .sendBugReportOrFeedback(this.reportBugFeedbackDTO)
      .subscribe((res: any) => {
        const respData = res;
        if (respData.success == 0) {
          $('#haveFeedback').modal('hide');
          this.resetFormData(form);
        } else {
          $('#haveFeedback').modal('hide');
          this.resetFormData(form);
          console.log('Error while sending report bug or feedback');
        }
      });
  }

  resetFormData(form: NgForm) {
    form.resetForm();
    this.reportBugFeedbackDTO = new ReportBugFeedbackDTO();
  }

}
