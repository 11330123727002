/**
 * Set logflag when user loggedIn in Application
 * SetCookie when remMe is selected and Remove cookie when user is logOut
 */
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { SessionStorageService } from 'angular-web-storage';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  currentDate: Date | undefined;
  public loggedIn: any;

  constructor(
    private cookieService: CookieService,
    public session: SessionStorageService
  ) { }

  // loggedIn variable store true that indicate any user is logged in.
  SetLogFlag(): void {
    this.loggedIn = true;
    this.session.set('loggedIn', this.loggedIn);
  }

  // get loggedIn flag stored in session.
  getLogflag(): string {
    return this.session.get('loggedIn');
  }

  // removeLogFlag is deleted from cookies on clicking of logOut button
  removeLogFlag(): void {
    this.session.set('loggedIn', '');
  }

  // set UserDTO stored in session.
  setSessionData(data: any) {
    this.session.set('userDTO', data);
  }

  // sets cookie for 7 days.
  setCookie(data: any): void {
    this.currentDate = new Date();
    // to add 7 days to current date
    this.currentDate.setDate(this.currentDate.getDate() + 7);
    this.cookieService.set('keekuUser', 'true', this.currentDate);
    this.cookieService.set(
      'logUserDetails',
      JSON.stringify(data),
      this.currentDate
    );
  }

  // removes a stored cookie.
  removeCookie(): void {
    this.cookieService.delete('keekuUser');
    this.cookieService.delete('logUserDetails');
    this.session.clear();
  }

  // getting a user stored in session.
  getUser(): any {
    return this.session.get('userDTO');
  }

  getToken() {
    return this.session.get('token');
  }

  setToken(token: String) {
    this.session.set('token', token);
  }

  // set UserDTO stored in session.
  setUserDetails(data: any) {
    this.session.set('user', data);
  }

  // getting a user stored in session.
  getUserDetails(): any {
    return this.session.get('user');
  }

  // set represenatative data
  setRepresentativeList(data: any) {
    this.session.set('representativeList', data);
  }

  // get representative data
  getRepresentativeList(): any {
    return this.session.get('representativeList');
  }
}
