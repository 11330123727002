import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { MultiSelect, CheckBoxSelection } from '@syncfusion/ej2-dropdowns';
import {
  CheckBoxSelectionService,
  MultiSelectComponent,
} from '@syncfusion/ej2-angular-dropdowns';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { isNull } from 'util';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import {
  Category,
  Subcategory,
  TemplateService,
  Section,
  User,
} from '../../core';
import { LoaderService } from '../../core/loader.service';
import { Country } from '../../core/models/country.model';
import { Profile } from '../../core/models/profile.model';
import { AuthService } from '../../core/services/auth.service';
import { DesignService } from '../../core/services/design.service';
import { ProfileService } from '../../core/services/profile.service';
import { ShepherdService } from 'angular-shepherd';

MultiSelect.Inject(CheckBoxSelection);

declare var $: any;

@Component({
  selector: 'app-create-profile',
  templateUrl: './create-profile.component.html',
  styleUrls: ['./create-profile.component.scss'],
  providers: [CheckBoxSelectionService],
})
export class CreateProfileComponent implements OnInit {
  @Input('isPopup') isPopup: boolean = false;
  @Input('name') username: string = '';

  public selectedImg: any = '../../../assets/images/noprofile2.png'; // default snowman image set

  @ViewChild('datepicker') public datepicker: DatePickerComponent | undefined;
  @ViewChild('multiselectcheckbox') public multiselectcheckbox:
    | CheckBoxSelection
    | undefined;
  @ViewChild('dropDownMultiSelect') public dropDownMultiSelect:
    | MultiSelectComponent
    | undefined;
  @ViewChild('categoryForm')
  public categoryForm!: NgForm;

  profile = new Profile(); // profile object
  profileImage: File | null = null; // used while uploading a profile image.
  profileCategoryList = new Array<Category>(); // List of categories to be set in the profile object.
  categoryList = new Array<Category>(); // list of category
  selectedCategory = new Category(); // the selected category, used in the drop down of category.
  selectedCategoryId: number[] = []; // selected catgory id , used to call the APi for getting the subcategories and sections.
  selectedCategoryList = new Array<Category>(); // the subcategories that has been selected in the dropdown.
  subCategoryPool = new Array<Subcategory>(); // a pool of subcategories so that subcategory details can be used when necessary.
  subcategoryList = new Array<Subcategory>(); // List of subcategory
  selectedSubcategoryId: number[] = []; // Used for getting the selected subcategories ID for multiselectdropdown
  subcategoryListForDataSource: any[] = [];
  sectionList: any[] = []; // sections of the category
  showNextButton: boolean = false; // flag to show Next button only if category is selected.
  showPreButton: boolean = false; // flag to show Previous button only if category is selected.
  showCreateButton: boolean = false; // flag to show create button only if category is selected.
  subCatEnabled: boolean = false; //flag to enable subcataegory only if category is selected
  chkboxlable: string = ''; // Use for Lable Animation
  temp: any; // used in event for setting the profile image.
  firstLogin: boolean; // to chack if first login or not
  tagNameFound: boolean = false; // check keeku hendle is found or not
  imageExceed: boolean = false; // TO show error when image size exceeded than limit.
  imageTypeInvalid: boolean = false;
  showErrors: boolean = false;
  previousLink: string = 'profileDetailsTab';
  previousBtn: boolean = false;
  usrnameDisabled: boolean = false;
  cropedImg: any = '';
  imageChangedEvent: any = '';
  addMoreSectionValidation: boolean = false;
  country: Country[] = [];
  checkProfile: boolean = false;
  field_: string = 'field';
  createMyPageNowButton: boolean = false;
  progressBar = 90;
  user = new User();
  representativeList: Array<String> = [];

  constructor(
    private templateService: TemplateService,
    private profileService: ProfileService,
    private router: Router,
    private authService: AuthService,
    private designService: DesignService,
    private readonly elementRef: ElementRef,
    private loaderService: LoaderService,
    private title: Title,
    private meta: Meta,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) {
    this.user = this.authService.getUser();
    var user = this.authService.getUser();
    this.firstLogin = user['firstLogIn'];
  
    if (this.firstLogin) {
      this.profile.profileName = user['firstName'] + ' ' + user['lastName'];
      //this.profile.tagName = user['userName'];
    }
    this.title.setTitle('CitizeX');
    let description =
      'CitizeX is social networking service based on United states politicians where all levels of government politicians can interact with message known as "Post a Review." Verified politicians can give review and response, but unregistered politicians can only read them.';
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.removeTag('name= "keywords"');
  }

  ngOnInit() {
    $('html, body').animate({ scrollTop: 0 }, '300');
    if (!this.isPopup) {
      this.designService.setHeaderStyle('profile');
    }
    this.getCategories(); // get the profile in detail for viewing.
    this.subCatEnabled = false;
    if (this.isPopup) {
      this.categoryForm.reset();
      this.profile.tagName = this.username;
      this.usrnameDisabled = true;
    }
  }

  // For getting the categories
  getCategories() {
    this.templateService.fetchCategoryDetails().subscribe((res) => {
      if (res.success === 0) {
        this.showCreateButton = false;
        if (this.firstLogin) {
          let individualCat = new Array<Category>();
          individualCat = res.data;
          individualCat.forEach((x) => {
            if (x.name == 'Individual') {
              this.categoryList.push(x);
            }
          });
        } else {
          this.categoryList = res.data;
        }
      }
    });
  }

  // To get the subcategories and sections of the selected category from the dropdown
  getSubcategoryAndSections(event: any) {
    if (this.isPopup) this.loaderService.disable = true;
    this.sectionList = [];
    this.subcategoryList = [];
    this.subcategoryListForDataSource = [];
    this.selectedSubcategoryId = [];
    this.selectedCategoryId = [+event.target.value];
    // Get Category list from category ids
    this.selectedCategoryId.forEach((element) => {
      var category = this.categoryList.find((x) => x.id === element);
      if (category != undefined) {
        this.selectedCategory = category;
        const name = category.name;

        //get subcategory list from selected category
        this.selectedCategory.subCategories.forEach((subElement) => {
          if (
            !this.subcategoryListForDataSource.some(
              (item) => item.subCategoryId === subElement.subCategoryId
            )
          ) {
            this.subcategoryListForDataSource.push({
              ...subElement,
              categoryName: name,
              categoryId: element,
            });
          }
        });

        if (this.subcategoryListForDataSource.length > 0) {
          this.subCatEnabled = true;
        }

        const params = new HttpParams().set('categoryId', element.toString());
        this.templateService.fetchSectionDetails(params).subscribe((res) => {
          if (res.success === 0) {
            res.data.subData.forEach((sectionElement: any) => {
              if (
                !this.sectionList.some(
                  (item) => item.sectionId === sectionElement.sectionId
                )
              ) {
                var user = this.authService.getUser();
                if (this.firstLogin || user['defaultProfileId'] == null) {
                  if (sectionElement.name == 'Personal Details') {
                    sectionElement.fields.forEach((element: any) => {
                      element.disabled = false;
                      if (element.title == 'First and Middle Name') {
                        element.value = user['firstName'];
                        element.disabled = true;
                      }
                      if (element.title == 'Last Name') {
                        element.value = user['lastName'];
                        element.disabled = true;
                      }
                      if (element.title == 'Date of Birth') {
                        console.log('dob:' + user['dateOfBirth']);
                        user['dateOfBirth'] = this.datePipe.transform(
                          user['dateOfBirth'],
                          'yyyy-MM-dd'
                        );
                        console.log('modified dob:' + user['dateOfBirth']);
                        element.value = user['dateOfBirth'];
                        element.disabled = true;
                      }
                    });
                  } else {
                    sectionElement.fields.forEach((element: any) => {
                      element.disabled = false;
                    });
                  }
                } else {
                  sectionElement.fields.forEach((element: any) => {
                    element.disabled = false;
                  });
                }
                console.log(
                  sectionElement.name + ' - ' + sectionElement.forAdmin
                );
                if (sectionElement.forAdmin == null) {
                  this.sectionList.push({
                    ...sectionElement,
                    categoryName: name,
                    categoryId: element,
                  });
                } else if (
                  sectionElement.forAdmin != undefined &&
                  sectionElement.forAdmin == false
                ) {
                  this.sectionList.push({
                    ...sectionElement,
                    categoryName: name,
                    categoryId: element,
                  });
                }
              }
            });
            this.showNextButton = true;
            if (this.user.defaultProfileId == null) {
              this.createMyPageNowButton = true;
            }
          }
        });
      }
    });
  }

  // getting the sections of the sub-categories, Here firstly the subcategory List is set, and for each of them section in retrived
  // Here two for loops are used because the find method runs in loop, and it becomes complicated code, 2 for loops gives accurate data.
  getSubCategorySections(event: any) {
    if (this.isPopup) this.loaderService.disable = true;
    if (event.target.checked) {
      this.selectedSubcategoryId.push(+event.target.value);
      this.selectedSubcategoryId.forEach((element) => {
        if (
          !this.subcategoryList.some((item) => item.subCategoryId === element)
        ) {
          const params = new HttpParams().set('categoryId', element.toString());
          this.templateService.fetchSectionDetails(params).subscribe((res) => {
            if (res.success === 0) {
              if (
                !this.subcategoryList.some(
                  (item) => item.subCategoryId === res.data.subCategoryId
                )
              ) {
                this.subcategoryList.push({
                  ...res.data,
                  sections: res.data.subData,
                });
              }
            }
            this.subcategoryList.forEach((obj) => {
              obj.sections.forEach((section) => {
                section.fields.forEach((field) => {
                  field.disabled = false;
                });
              });
            });
          });
        }
      });
    } else {
      this.deleteSubCategory(+event.target.value);
    }
  }

  // for checking profile form valid or not
  checkCreateProfile() {
    if (this.categoryForm.invalid) {
      this.showErrors = true;
      this.checkProfile = true;
      const formGroupName = this.previousLink.slice(0, -3) + '_Group';
      const formGroup: any = this.categoryForm.form.controls[formGroupName];

      if (formGroup.invalid && !this.previousBtn) {
        Object.keys(formGroup.controls).every((key) => {
          const field = formGroup.get(key);
          if (field.invalid === true) {
            const link = this.elementRef.nativeElement.querySelector(`#${key}`);
            if (link) link.focus();
            return false;
          }
          return true;
        });

        this.showErrors = true;
        return;
      }
      return;
    }
    this.checkProfile = false;
    var user = this.authService.getUser();
    this.firstLogin = user['firstLogIn'];

    if (user['firstLogIn'] == true) {
      // create profile as first login.
      this.createProfile('create');
    } else {
      if (this.selectedCategory.name == 'Individual') {
        // create profile as selected category is Individual.
        this.createProfile('create');
      }
      if (this.selectedCategory.name == 'Organization') {
        // create profile as selected category is Individual.
        this.createProfile('create');
      } else {
        // showing create page model
        //$('#createPageModal').modal('show');
      }
    }
  }

  // for creating the profile. submiting the profile object to the database to store.
  createProfile(result: any) {
    // setting profile details
    var user = this.authService.getUser();
    this.profile.createdBy = user['userId'];
    this.profile.defaultProfile = user['firstLogIn'];
    this.profile.updatedDate = null;
    this.profile.updatedBy = null;
    delete this.profile.updatedDate;
    delete this.profile.updatedBy;
    delete this.profile.blockProfileDTO;
    delete this.profile.blockedBySearchProfile;
    delete this.profile.blockedSearchProfile;
    delete this.profile.verified;
    delete this.profile.lastIndex;

    if (result === 'createWithClaimed') {
      this.profile.claimedFlag = 'Y';
      this.profile.claimedUserId = user['userId'];
    } else {
      if (user['firstLogIn'] == true) {
        this.profile.claimedFlag = 'Y';
        this.profile.claimedUserId = user['userId'];
      } else {
        this.profile.claimedFlag = 'N';
      }
    }

    // setting sections of categories
    this.sectionList.forEach((section) => {
      let tempCat = new Category();
      var cat = this.categoryList.find((x) => x.name == section.categoryName);
      if (cat != undefined) {
        tempCat = cat;
      }
      //let profCat = new Category();
      let profCat = this.profileCategoryList.find(
        (x) => x.name == section.categoryName
      );
      if (profCat != undefined) {
        // if category found in profile category
        delete section.categoryName;
        delete section.categoryId;
        profCat.sections.push(section); // inserting a section
        profCat.subCategories = new Array<Subcategory>();
        this.profileCategoryList[this.profileCategoryList.indexOf(profCat)] =
          profCat; // updating a category.
      } else {
        // inserting new category and setting its section
        delete section.categoryName;
        delete section.categoryId;
        tempCat.sections = new Array<Section>();
        tempCat.sections.push(section);
        tempCat.subCategories.forEach((subcat) => {
          this.subCategoryPool.push(subcat);
        });
        tempCat.subCategories = new Array<Subcategory>();
        this.profileCategoryList.push(tempCat);
      }
    });

    //setting subcategories
    this.subcategoryList.forEach((subCategory) => {
      //let profCat = new Category();
      let profCat = this.profileCategoryList.find(
        (x) => x.name == subCategory.name
      );
      let tempSubCat = this.subCategoryPool.find(
        (x) => x.subCategoryId == subCategory.subCategoryId
      );
      if (tempSubCat != undefined) {
        tempSubCat.sections = subCategory.sections;
        if (profCat != undefined) {
          this.profileCategoryList[
            this.profileCategoryList.indexOf(profCat)
          ].subCategories.push(tempSubCat);
        }
      }
    });

    // setting categories.
    this.profile.categories = this.profileCategoryList;
    const formData: FormData = new FormData();
    if (this.profileImage) {
      formData.append('imageFile', this.profileImage); // Append only if profileImage is not null
    }
    formData.append('profileDTO', JSON.stringify(this.profile));
    // return;
    this.profileService.createProfile(formData).subscribe((res) => {
      const respData = res;
      let tempId = respData.data.tagName;
      let uri = '/profile/view-profile/' + tempId;
      if (res.success == 0) {
        // Need to update FirstLogin flag in authService
        if (this.profile.defaultProfile) {
          let user = this.authService.getUser();
          this.getRepresentative();
          user.defaultProfileId = respData.data.profileId;
          user.defaultProfileImage = respData.data.profileImage;
          user.defaultProfileName = respData.data.profileName;
          user.defaultProfileTagName = respData.data.tagName;
          user.firstLogIn = false;
          this.authService.setSessionData(user);
          this.router.navigateByUrl(uri, {
            state: { reload: false, tempId: tempId, fromCreateProfile: true }, // Add the flag here
          });
        } else {
          if (this.isPopup) {
            this.categoryForm.reset();
            this.categoryList = [];
            this.subcategoryList = [];
            $('#createProfileModal').modal('hide');
            $('#postReviewModel').removeClass('slide-right');
            $('#postReviewToProfileModel').removeClass('slide-right');
            this.designService.showCreatePopup = false;
          } else {
            this.router.navigateByUrl(uri, {
              state: { reload: false, tempId: tempId },
            });
          }
        }
      } else {
      }
    });
  }

  // Uploading the profile image.
  uploadProfile(event: any) {
    if (this.imageChangedEvent === false || this.imageChangedEvent === '') {
      console.log('profileImg : ', this.profileImage);
    } else {
      this.selectedImg = this.cropedImg;
      //this.profileImage = this.base64ToFile(this.selectedImg, 'test');
      const file = this.convertBlobUrlToFile(this.selectedImg, 'jpg');
      file.then((value: any) => {
        this.profileImage = value;
      });
      this.modalClose('uploadProfileModal');
    }
  }

  // Gets called on delete of Category dropdwon
  deleteCategory(event: any) {
    let id: number;
    if (
      event.e.keyCode == 8 ||
      event.e.key == 'Backspace' ||
      event.e.code == 'Backspace'
    ) {
      id = this.selectedCategory.id;
    } else {
      id = event.itemData.id;
    }
    const rmId = id;
    const category = this.categoryList.find((x) => x.id === rmId);
    // remove section of removed category
    this.sectionList = this.sectionList.filter(
      (item) => item.categoryId !== rmId
    );

    // get subcategory list from selected category
    if (category != undefined) {
      category.subCategories.forEach((subElement) => {
        if (
          this.selectedSubcategoryId.some(
            (item) => item === subElement.subCategoryId
          )
        ) {
          this.subcategoryList = this.subcategoryList.filter(
            (item) => item.subCategoryId !== subElement.subCategoryId
          );
          this.selectedSubcategoryId.splice(
            this.selectedSubcategoryId.indexOf(subElement.subCategoryId),
            1
          );
        }
      });
    }

    if (this.selectedCategoryId.length === 1) {
      this.subcategoryListForDataSource = [];
      this.selectedSubcategoryId = [];
    }

    if (this.selectedCategoryId.length > 1) {
      this.subCatEnabled = true;
    } else {
      this.subCatEnabled = false;
    }
  }

  // Gets called on delete of Subcategory dropdwon
  deleteSubCategory(event: any) {
    // Logic for while subcategory is removed then all section related to that subcategory got deleted.
    const index = this.selectedSubcategoryId.indexOf(event);
    this.selectedSubcategoryId.splice(index, 1);
    this.subcategoryList = this.subcategoryList.filter(
      (item) => item.subCategoryId !== event
    );
  }

  checkExistingKeekuHandle() {
    if (this.profile.tagName != ' ' && this.profile.tagName != null) {
      this.loaderService.disable = true;
      const params = new HttpParams().set('tagName', this.profile.tagName);
      this.profileService.getProfileByTagName(params).subscribe((res) => {
        if (res.success === 0) {
         // this.profile = res.data;
          this.tagNameFound = true;
          this.profile.tagName = null;
          this.showCreateButton = false;
        } else {
          this.tagNameFound = false;
          this.showCreateButton = false;
        }
      });
    }
  }

  deleteProfilePicture() {
    this.selectedImg = '../../../assets/images/noprofile2.png';
    this.imageChangedEvent = false;
    this.cropedImg = null;
    this.profileImage = null;
  }

  addMoreSection(section: Section, categoryId: any) {
    let check: Boolean = false;
    let tempsection: any;
    tempsection = this.sectionList.find(
      (x) => x.sectionId == section.sectionId
    );
    tempsection.fields.forEach((element: any) => {
      if (!isNull(element.value)) {
        check = true;
      }
    });
    if (check == undefined) {
      check = false;
    }

    if (check == false) {
      this.addMoreSectionValidation = false;
      return;
    } else {
      this.addMoreSectionValidation = true;
    }

    const params = new HttpParams().set('categoryId', tempsection.categoryId);
    this.templateService.fetchSectionDetails(params).subscribe((res) => {
      if (res.success === 0) {
        res.data.subData.forEach((sectionElement: any) => {
          if (sectionElement.sectionId == tempsection.sectionId) {
            let tempSubSecList;
            if (section.subSections == undefined) {
              tempSubSecList = new Array<Section>();
            } else {
              tempSubSecList = section.subSections;
            }
            let tempSection = new Section();
            delete tempSection.subSections;
            tempSection.fields = sectionElement.fields;
            tempSection.fields.forEach((element) => {
              element.disabled = false;
            });
            tempSubSecList.push(tempSection);
            section.subSections = tempSubSecList;
          }
        });
      }
    });
  }

  parentWillTakeAction(message: any) {
    if (message != null) {
      var data = message.split(',', 2);
      console.log('from child : ' + data);

      this.sectionList.forEach((element) => {
        console.log(element);
        element.fields.forEach((field: any) => {
          console.log(field.title);
          if (field.title == 'city') {
            field.content = [];
            const params = new HttpParams().set('stateId', data[1]);
            this.profileService.getOfficeCity(params).subscribe((res) => {
              const respData = res;
              if (respData.success == 0) {
                console.log(respData.data);
                this.country = respData.data;
                let fieldValue = [];
                for (var i = 0; i < this.country.length; i++) {
                  fieldValue.push(this.country[i].name);
                }
                field.content = fieldValue;
              }
            });
          }
        });
      });

      /*  if (data[0] == 'office name')  { */
      this.sectionList.forEach((element) => {
        console.log(element);
        element.fields.forEach((field: any) => {
          console.log(field.title);
          if (field.title == 'office name') {
            field.content = [];
            const params = new HttpParams().set('city', data[1]);
            this.profileService.getOfficeByCity(params).subscribe((res) => {
              const respData = res;
              if (respData.success == 0) {
                field.content = respData.data;
              }
            });
          }
        });
      });
      // }
    }
    console.log(message);
  }

  deleteSection(section: Section, Subsection: Section) {
    let tempsection = new Section();
    tempsection = this.sectionList.find(
      (x) => x.sectionId == section.sectionId
    );
    this.sectionList.forEach((element) => {
      if (element.sectionId == tempsection.sectionId) {
        element.subSections.splice(element.subSections.indexOf(Subsection), 1);
      }
    });
  }

  addSectionInSubcategory(section: Section, subCategoryId: any) {
    let check: Boolean = false;
    let tempsection: Section | undefined;
    let subcategory = this.subcategoryList.find(
      (x) => x.subCategoryId == subCategoryId
    );
    if (subcategory != undefined) {
      let subCategorySectionList = subcategory.sections;
      tempsection = subCategorySectionList.find(
        (x) => x.sectionId == section.sectionId
      );

      if (tempsection != undefined) {
        tempsection.fields.forEach((element) => {
          if (!isNull(element.value)) {
            check = true;
          }
        });
      }
    }
    if (check == undefined) {
      check = false;
    }

    if (check == false) {
      this.addMoreSectionValidation = false;
      return;
    } else {
      this.addMoreSectionValidation = true;
    }

    const params = new HttpParams().set('categoryId', subCategoryId);
    this.templateService.fetchSectionDetails(params).subscribe((res) => {
      if (res.success === 0) {
        res.data.subData.forEach((sectionElement: any) => {
          if (tempsection != undefined) {
            if (sectionElement.sectionId == tempsection.sectionId) {
              let tempSubSecList;
              if (section.subSections == undefined) {
                tempSubSecList = new Array<Section>();
              } else {
                tempSubSecList = section.subSections;
              }
              let tempSection = new Section();
              delete tempSection.subSections;
              tempSection.fields = sectionElement.fields;
              tempSection.fields.forEach((element) => {
                element.disabled = false;
              });
              tempSubSecList.push(tempSection);
              section.subSections = tempSubSecList;
            }
          }
        });
      }
    });
  }

  deleteSectionInSubCategory(
    section: Section,
    subCategoryId: any,
    Subsection: Section
  ) {
    let tempsection: Section | undefined;
    let subcategory = this.subcategoryList.find(
      (x) => x.subCategoryId == subCategoryId
    );
    if (subcategory != undefined) {
      tempsection = subcategory.sections.find(
        (x) => x.sectionId == section.sectionId
      );
      subcategory.sections.forEach((element) => {
        if (tempsection != undefined) {
          if (element.sectionId == tempsection.sectionId) {
            if (element.subSections != undefined) {
              element.subSections.splice(
                element.subSections.indexOf(Subsection),
                1
              );
            }
          }
        }
      });
    }
  }

  elementNumbering() {
    const link = this.elementRef.nativeElement.querySelectorAll('.nav-link');
    link.forEach((element: any, index: any) => {
      const myIndex = index + 1;
      element.querySelector('.step-icon').innerHTML = myIndex;
      element.querySelector('.step-current').innerHTML = myIndex;
      element.querySelector('.step-total').innerHTML = link.length;
    });
  }

  nextTab() {
    this.previousBtn = false;
    const link =
      this.elementRef.nativeElement.querySelector('.nav-link.active');
    if (link.nextElementSibling) {
      link.focus();
      link.nextElementSibling.click('text');
    }
  }

  previousTab() {
    this.previousBtn = true;
    const link =
      this.elementRef.nativeElement.querySelector('.nav-link.active');
    if (link.previousElementSibling) {
      link.focus();
      link.previousElementSibling.click();
    }
  }

  showTab(element: any, event: any) {
    const formGroupName = this.previousLink.slice(0, -3) + '_Group';
    const formGroup: any = this.categoryForm.form.controls[formGroupName];

    // if (this.selectedCategoryId.length === 0 && this.selectedSubcategoryId.length === 0) {
    //   return;
    // }

    if (formGroup.invalid && !this.previousBtn) {
      Object.keys(formGroup.controls).every((key) => {
        const field = formGroup.get(key);
        if (field.invalid === true) {
          const link = this.elementRef.nativeElement.querySelector(`#${key}`);
          if (link) link.focus();
          return false;
        }
        return true;
      });

      this.showErrors = true;

      event.stopPropagation();
      event.preventDefault();
      return;
    } else {
      // if previous tab values are valid
      this.showErrors = false;
      const link = this.elementRef.nativeElement.querySelector(
        '#' + element.id
      );
      link.focus();
      console.log("link:"+link.id)
      this.previousLink = link.id;
      this.showPreButton = link.previousElementSibling ? true : false;
      this.showNextButton = link.nextElementSibling ? true : false;
      this.showCreateButton = link.nextElementSibling ? false : true;
      if(this.previousLink == 'profileDetailsTab'){
        this.showCreateButton = false
      }
    }
  }

  modalClose(val: any) {
    $(`#${val}`).modal('hide');
  }

  fileChangeEvent(event: any): void {
    var files = event.srcElement.files;
    if (files[0].name !== '') {
      this.imageChangedEvent = event;
    } else {
      this.selectedImg = '../../../assets/images/noprofile2.png';
      this.imageChangedEvent = false;
      this.cropedImg = null;
      this.profileImage = null;
      this.modalClose('uploadProfileModal');
      return;
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    // this.cropedImg = event.base64;
    this.cropedImg = this.sanitizer.bypassSecurityTrustUrl(
      event.objectUrl || event.base64 || ''
    );
    //this.profileImage = this.base64ToFile(this.selectedImg, 'test');
    const file = this.convertBlobUrlToFile(this.selectedImg, 'jpg');
    file.then((value: any) => {
      this.profileImage = value;
    });
  }

  // Purpose: For converting bloburl to file
  async convertBlobUrlToFile(blobUrl: any, filename: string): Promise<File> {
    const response = await fetch(blobUrl.changingThisBreaksApplicationSecurity);
    const blob = await response.blob();

    // Convert the Blob into a File by creating a new File object
    const file = new File([blob], filename, { type: blob.type });

    return Promise.resolve(file);
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getRepresentative() {
    let address = this.user.address;
    if (address != null) {
    address = address.replace(/\n/g, ' ');
    const params = new HttpParams().set('address', address);

    this.loaderService.disable = true;

    this.profileService.getReprentatives(params).subscribe((res) => {
      if (res.success === 0) {
        console.log(JSON.stringify(res));
        res.data.forEach((obj: any) => {
          this.representativeList.push(obj.tagname);
        });
        console.log('Representative List:' + JSON.stringify(this.representativeList));
        this.authService.setRepresentativeList(this.representativeList);
        // window.location.reload();
      }
    });
  }
  }
   
}
